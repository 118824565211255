import { template as template_93f88442d31f46998f8779009375bdc2 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_93f88442d31f46998f8779009375bdc2(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
