import { template as template_bcc35f1a691a4e5b9f19dad5d343ef95 } from "@ember/template-compiler";
const FKText = template_bcc35f1a691a4e5b9f19dad5d343ef95(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
