import { template as template_8a10041cfc7d4cfbae65b5ff0334cc12 } from "@ember/template-compiler";
const WelcomeHeader = template_8a10041cfc7d4cfbae65b5ff0334cc12(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
