import { template as template_0832636a2a194f00a55c6cbc3b93b3d1 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { extractLinkMeta } from "discourse/lib/render-topic-featured-link";
import icon from "discourse-common/helpers/d-icon";
export default class FeaturedLink extends Component {
    get meta() {
        return extractLinkMeta(this.args.topicInfo);
    }
    static{
        template_0832636a2a194f00a55c6cbc3b93b3d1(`
    {{#if this.meta}}
      <a
        class="topic-featured-link"
        rel={{this.meta.rel}}
        target={{this.meta.target}}
        href={{this.meta.href}}
      >
        {{icon "up-right-from-square"}}
        {{this.meta.domain}}
      </a>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
